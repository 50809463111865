import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UserService } from '../../service/user.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { MessageService } from '../../messages/service/message.service';
import { FilingBlank } from '../model/filingblank';
import { FormBuilderService } from '../services/form-builder.service';
import { DependenciesService } from './services/dependencies.service';
import { User } from '../../model/user';
import { CommonConfirmationModalComponent } from '../../common-confirmation-modal/common-confirmation-modal.component';

@Component({
  selector: 'app-dependencies',
  templateUrl: './dependencies.component.html',
  styleUrls: ['./dependencies.component.css']
})
export class DependenciesComponent implements OnInit, OnDestroy {
  user: User;
  schema: string;
  title: string = '';
  subTitle: string = '';
  fieldNames: any = [];
  filingBlank: FilingBlank;
  filteredQuestions: any;
  title2: string;
  subTitle2: string;
  count = {
    current: 1
  };
  modalSubscriptions: Subscription[] = [];
  bsModalRef: BsModalRef;
  associatedRules: any[] = [];

  constructor(
    private userService: UserService,
    public formBuilderService: FormBuilderService,
    public dependenciesService: DependenciesService,
    public modalService: BsModalService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.title = 'Set Dependencies - Select Questions';
    this.title2 = 'Set Dependencies - Establish Rule(s)';
    this.subTitle = "Select the question to add a dependency rule.";
    this.subTitle2 = "Select the designated question's response value then set the element(s) to hide with the dependency rule. \nWhen the user's response matches the selected response, any selected elements (section's, column's and question's) will be hidden in the user's form.";
    this.fieldNames = this.formBuilderService.getFieldName();
    this.filingBlank = this.formBuilderService.getOriginalFilingBlank();
    this.filteredQuestions = this.getQuestionTextValues(this.filingBlank, this.fieldNames);
    this.setAssociatedRules();
    this.formBuilderService.isDependencyPage = true;
    this.formBuilderService.initiateFilingBlank.subscribe((value) => {
      if(value) {
        this.getFilingBlankLayout();
      }
    })
  }

  setAssociatedRules() {
    this.filteredQuestions.forEach(question => {
      const associatedRuleCount = this.associatedRules.filter(rule => rule === question.questionKey).length;
      question.associatedRuleCount = associatedRuleCount;
    });
  }

  ngOnDestroy(): void {
    this.formBuilderService.isDependencyPage = false;
    
  }

  getQuestionTextValues (section: any, fieldNames: any[], sectionName: string = ''): { question: string, section: string, primaryKeyCount: any, questionKey: string, isDependent: boolean }[] {
    const questionObjects: { question: string, section: string, primaryKeyCount: any, questionKey: string, isDependent: boolean }[] = [];
    if (sectionName === '') sectionName = section.key;
    section.questions.forEach((question: any) => {

      question.inputs.forEach((input: any) => {
        const matchingField = fieldNames.find((fieldName: any) =>fieldName.isEnumValue && fieldName.fieldNames === input.schemaPropertyNameKey);

        const isDependent = !!matchingField;
        const existingQuestion = questionObjects.find((obj) => obj.question === question.text);

        if (!existingQuestion) {
          questionObjects.push({
            question: question.text,
            section: sectionName,
            primaryKeyCount: question.rules.length,
            questionKey: question.key,
            isDependent: isDependent,
          });
        }
      });

      question.rules.forEach((rule: any) => {
        rule.ruleKeys.forEach((ruleKey: any) => {
          this.associatedRules.push(ruleKey.key);
        });
      });
    });
  
    if (section.sections) {
      section.sections.forEach((subsection: any) => {
        const subsectionQuestionObjects = this.getQuestionTextValues(subsection, fieldNames, subsection.key);
        questionObjects.push(...subsectionQuestionObjects);
      });
    }
    return questionObjects;
  }

  goToTop() {
    const scrollFormElement = document.querySelector('.scrollForm');
    if (scrollFormElement) {
      scrollFormElement.scrollTop = 0;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  goBackToQuestions() {
    const initialState = {
      message: 'Do you wish to proceed? Any changes will be lost.',
      title: 'Form Builder'
    };
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    if (!isFormChanged) {
      const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
      bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
        if (data) {
          this.dependenciesService.isSelectQuestionsVisible.next(true);
          this.dependenciesService.isDependencyQuestionClicked.next(false);
        }
        bsModalRef.hide();
      });
    } else {
      this.dependenciesService.isSelectQuestionsVisible.next(true);
      this.dependenciesService.isDependencyQuestionClicked.next(false);
    }
  }

  getFilingBlankLayout() {
    this.formBuilderService.loading.next(true);
    this.formBuilderService.getFilingBlankLayout(this.user.currentSchema).subscribe(
      (res) => {
        if (res.status === 200) {
          this.filingBlank = res.body;
          this.formBuilderService.setFilingBlank(this.filingBlank);
          this.formBuilderService.setOriginalFilingBlank(this.filingBlank);
          this.dependenciesService.isQuestionSelected = false;
          this.formBuilderService.loading.next(false);
          this.goToTop();
        }
      }, (err) => {
        this.formBuilderService.loading.next(false);
      }
    )
  }

  saveFilingBlank() {
    this.messageService.clear();
    let filingBlank = new FilingBlank();
    filingBlank.sections = this.dependenciesService.clonedArray;
    this.formBuilderService.showErrorMessages.next(false);
    this.formBuilderService.loading.next(true);
    this.formBuilderService.putFilingBlankLayout(this.schema, filingBlank)
      .subscribe(res => {
        this.getFilingBlankLayout();
        let messageText = `Dependency rule(s) saved successfully`;
        this.messageService.add(new AlertMessage(messageText, 'success'));
      });
  }

  isSaveDisabled(): boolean {
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    return isFormChanged;
  }

  cancel() {
    this.getFilingBlankLayout();
    this.cdr.detectChanges();
  }
}
