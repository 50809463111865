<ngx-loading [show]="loading"></ngx-loading>
<div id="cross-field-validation-maintenance" class="wrapper">
    <!-- <div style="height: calc(100vh - 265px);"> -->
    <div>

        <div class="row">
            <div class="col-12">
                <h1>{{ title }}</h1>
            </div>
        </div>
        <ng-container *ngIf="showUserAssignmentsPage; else noAssignmentFieldSelected">

            <h4>Empty value and Search returns all assignments.  Entered value returns exact match assignment.  Filter option available on sandwich menu of table headers.</h4>

            <div class="row">
                <div class="col-12">
                    <app-messages></app-messages>
                </div>
            </div>

            <div class="col mb-3">
                <div class="col-6">
                    <ul class="list-inline">
                        <li *ngIf="showError" class="pb-2 text-danger">{{transformedAssignmentKeyFieldName}} {{selectedAssignmentValue}} already exists</li>
                        <li class="list-inline-item">
                            <label *ngIf="!showError">{{transformedAssignmentKeyFieldName }}</label>
                            <input id="assignmentKey:{{transformedAssignmentKeyFieldName}}"
                                placeholder="{{transformedAssignmentKeyFieldName }}" class="form-control"
                                [(ngModel)]="selectedAssignmentValue" (keyup.enter)="getUserAssignmentsOnEnter(selectedAssignmentValue)"
                                (keyup)="onInputChange($event)"/>
                        </li>
                        <li class="list-inline-item">
                            <button id="btn:search" type="button" class="btn btn-primary"
                                (click)="getUserAssignments(selectedAssignmentValue)">
                                <i class="fa fa-search"></i>
                                Search
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-6">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <label>Add:</label>
                            <input id="add" type="text" placeholder="User ID" class="form-control"
                                [(ngModel)]="userIdToBeAdded" [disabled]="addInputTextAndButtonDisable()" required />
                        </li>
                        <li class="list-inline-item">
                            <button id="btn:add" type="button" class="btn btn-primary"
                                (click)="addUserId(userIdToBeAdded)" [disabled]="addInputTextAndButtonDisable()"><i
                                    class="fa fa-plus"></i>
                                Add
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="rowData && rowData.length > 0; else noData">
                <div class="row mb-3">
                    <div class="col-12">
                        <ag-grid-angular *ngIf="rowData && rowData.length > 0" style="width: 100%; height: 400px;"
                            #agGrid id="userAssignmentsGrid" class="ag-theme-balham" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [rowData]="rowData" [context]="context"
                            [getRowHeight]="getRowHeight" [frameworkComponents]="frameworkComponents"
                            (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
            <ng-template #noData>
                <div class="d-flex justify-content-center align-items-center vh-25" *ngIf="userRole !== 'primaryUser'">
                  <div class="text-center">
                    <p *ngIf=!loading>No assignments</p>
                  </div>
                </div>
              </ng-template>
        </ng-container>

    </div>

</div>

<ng-template #noAssignmentFieldSelected>
    <div class="pt-3 pb-3">
        <h4 id="noAssignmentKeySelected" class="text-center">{{noAssignmentKeySelectedMessage}}</h4>
    </div>
</ng-template>