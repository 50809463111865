import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { LoadingModule } from "ngx-loading";
import { MessagesModule } from "../messages/messages.module";
import { PipesModule } from "../pipes/pipes.module";
import { userAssignmentRoutingModule } from "./user-assignment-routing.module";
import { UserAssignmentComponent } from "./user-assignment.component";
import { UserAssignmentService } from "./services/user-assignment.service";
import { RemoveUserAssignmentComponent } from './cell-renderer/remove-user-assignment/remove-user-assignment.component';
import { SecondaryUserAssignmentComponent } from './cell-renderer/secondary-user-assignment/secondary-user-assignment.component';
import { PrimaryUserAssignmentComponent } from "./cell-renderer/primary-user-assignment/primary-user-assignment.component";
import { AlertModule } from "ngx-bootstrap/alert";


@NgModule({
  imports: [
    CommonModule,
    NgJsonEditorModule,
    FormsModule,
    MessagesModule,
    LoadingModule,
    userAssignmentRoutingModule,
    AgGridModule.withComponents([RemoveUserAssignmentComponent, PrimaryUserAssignmentComponent, SecondaryUserAssignmentComponent]),
    PipesModule,
    AlertModule
  ],
  declarations: [UserAssignmentComponent, RemoveUserAssignmentComponent, PrimaryUserAssignmentComponent, SecondaryUserAssignmentComponent],
  providers: [UserAssignmentService],
  exports: [UserAssignmentComponent]
})
export class UserAssignmentModule { }
